import {
    clone,
    isEqual,
    isNil,
} from 'lodash';

export default (
    state,
    params,
    query,
    filters,
    DEFAULT_FILTER_OPTIONS,
    reset = false,
    updateSearchQuery = false,
    updateFilters = false,
    applyFilters = false,
    clearFilters = false,
) => {
    if (reset || isNil(state)) {
        return {
            areFiltersDirty: false,
            draftOptions: { ...DEFAULT_FILTER_OPTIONS },
            filterOptions: { ...DEFAULT_FILTER_OPTIONS },
            isFiltering: false,
            params,
            searchQuery: '',
        };
    }

    if (updateSearchQuery) {
        return {
            ...state,
            searchQuery: query,
        };
    }

    if (updateFilters) {
        return {
            ...state,
            areFiltersDirty: !isEqual(state.filterOptions, filters),
            draftOptions: filters,
            isFiltering: !isEqual(state.filterOptions, DEFAULT_FILTER_OPTIONS),
        };
    }

    if (applyFilters) {
        return {
            ...state,
            areFiltersDirty: false,
            filterOptions: clone(state.draftOptions),
            isFiltering: !isEqual(state.draftOptions, DEFAULT_FILTER_OPTIONS),
        };
    }

    if (clearFilters) {
        return {
            ...state,
            areFiltersDirty: true,
            draftOptions: { ...DEFAULT_FILTER_OPTIONS },
        };
    }

    return state;
};
