import React from 'react';
import {
    Box,
    Grid,
    Icon,
    Image,
    Modal,
    Typography,
} from '@saddlebackchurch/react-cm-ui';
import { withStyles } from '@saddlebackchurch/react-cm-ui/core/styles'; // eslint-disable-line import/extensions
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import AppStore from '../../js/stores/AppStore.js';
import BannerUtils from '../../js/utils/BannerUtils.js';
import { i18n } from '../constants.js';
import {
    fileAllowedTypes,
    IMAGE_TYPE,
    inputAllowedTypes,
    GROUP_PROP_TYPES,
} from './constants';
import ImageUploaderManager from './imageUploaderManager.jsx';

const propTypes = {
    classes: PropTypes.shape({
        divContainer: PropTypes.string,
        emptyImage: PropTypes.string,
        headerColumn: PropTypes.string,
        image: PropTypes.string,
        fileUploadSection: PropTypes.string,
        inputTypeFile: PropTypes.string,
        scrollContainer: PropTypes.string,
        uploadPhotoLink: PropTypes.string,
        contentScrollContainer: PropTypes.string,
        contentWrapper: PropTypes.string,
    }),
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    index: PropTypes.number,
    group: GROUP_PROP_TYPES.isRequired,
    onChange: PropTypes.func.isRequired,
    previewUrl: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
};

const defaultProps = {
    classes: {},
    disabled: false,
    index: null,
    readOnly: false,
};

const BLOCK_CLASS_NAME = 'image_uploader';

const styles = ({
    breakpoints,
    palette,
    spacing,
}) => ({
    root: {
        [`& .${BLOCK_CLASS_NAME}--input_file_button_wrapper`]: {
            marginRight: spacing(1),
            padding: `8px ${spacing(2)}px !important`,
        },
        padding: '0px 0px 0px 0px',
    },
    fileUploadSection: {
        alignItems: 'center',
        display: 'flex',
    },
    inputTypeFile: {
        display: 'none',
    },
    uploadPhotoLink: {
        color: palette.text.link,
        cursor: 'pointer',
        fontSize: '14px',
        marginLeft: spacing(2),
        [breakpoints.down('sm')]: {
            marginLeft: 0,
        },
    },
    scrollContainer: {
        padding: '0px 0px 0px 0px',
    },
    emptyImage: {
        fontSize: '24px',
        border: `2px dashed ${palette.border.primary}`,
        marginBottom: '5px',
        backgroundColor: palette.common.white,
        borderRadius: '0px',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    },
    headerColumn: {
        alignItems: 'flex-start',
        display: 'flex',
        flex: 1,
        minHeight: spacing(5),
    },
    image: {
        fontSize: '24px',
        color: '#e6e9ec', // does not appear to be a theme color
        marginBottom: '15px',
        backgroundColor: palette.common.white,
        borderRadius: '0px',
    },
    divContainer: {
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        width: '100%',
    },
    contentScrollContainer: {
        maxHeight: '90vh',
    },
    contentWrapper: {
        paddingBottom: spacing(2),
    },
});

const reader = new FileReader();

class ImageUploaderContent extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isImageSaved: false,
            isOpenImageModal: false,
            image: null,
            fileName: '',
        };

        this.onCloseImageModal = this.onCloseImageModal.bind(this);
        this.onHandleImageUploaderChange = this.onHandleImageUploaderChange.bind(this);
        this.onImageModalToggle = this.onImageModalToggle.bind(this);
        this.onRemoveImage = this.onRemoveImage.bind(this);
        this.onSetImage = this.onSetImage.bind(this);
    }

    onCloseImageModal() {
        this.setState({
            isOpenImageModal: false,
            isImageSaved: false,
            image: null,
            fileName: '',
        });
    }

    onHandleImageUploaderChange(event, modalOpen = false) {
        event.preventDefault();

        const file = event.target.files[0];
        const fileName = event.target.files[0].name;

        if (!file) {
            return;
        }

        if (file.size > AppStore.getUploadingOptions().maxEmailAttachmentSize) {
            BannerUtils.addBanner({
                id: `${BLOCK_CLASS_NAME}--attachment_too_large`,
                level: 'error',
                timeout: true,
                title: i18n('The attachment is too large'),
                type: 'notification',
            });

            return;
        }

        if (!fileAllowedTypes.includes(file.type)) {
            BannerUtils.addBanner({
                id: `${BLOCK_CLASS_NAME}--invalid_file_format`,
                level: 'error',
                timeout: true,
                title: i18n('Invalid File Format'),
                type: 'notification',
            });

            return;
        }

        reader.onloadend = () => {
            this.setState({ image: reader.result,
                fileName });
        };

        // eslint-disable-next-line no-param-reassign
        event.target.value = '';

        reader.readAsDataURL(file);

        if (modalOpen) {
            this.onImageModalToggle();
        }
    }

    onImageModalToggle() {
        this.setState((prevState) => ({
            isOpenImageModal: !prevState.isOpenImageModal,
        }));
    }

    onRemoveImage() {
        const {
            onChange,
            group,
            index,
        } = this.props;

        const newGroup = {
            ...group,
            imageContainer: {
                ...group.imageContainer,
                fileName: '',
                src: '',
            },
        };

        this.onSetImage('', '');

        this.setState({
            isImageSaved: false,
            image: null,
            fileName: '',
        }, () => {
            onChange(newGroup, index);
        });
    }

    onSetImage(name, url) {
        const {
            onChange,
            group,
            index,
        } = this.props;

        const {
            isOpenImageModal,
        } = this.state;

        const newGroup = {
            ...group,
            imageContainer: {
                ...group.imageContainer,
                fileName: name,
                src: url,
            },
        };

        onChange(newGroup, index);

        if (isOpenImageModal) {
            this.onImageModalToggle();
        }
    }

    // TODO: IMAGE_TYPE.event is temp because there is only one endpoint for webheader, once in events endpoints for appheader/thumbnail are available need to come back
    getContainerHeight(id) {
        if (id === IMAGE_TYPE.event) return '';
        if (id === IMAGE_TYPE.thumbnail) return '80px';

        return '110px';
    }

    // TODO: IMAGE_TYPE.event is temp because there is only one endpoint for webheader, once in events endpoints for appheader/thumbnail are available need to come back
    getContainerMinHeight(id) {
        if (id === IMAGE_TYPE.event) return '110px';

        return '';
    }

    // TODO: IMAGE_TYPE.event is temp because there is only one endpoint for webheader, once in events endpoints for appheader/thumbnail are available need to come back
    getContainerWidth(id) {
        if (id === IMAGE_TYPE.event) return '100%';
        if (id === IMAGE_TYPE.thumbnail) return '80px';

        return '100%';
    }

    getThumbnailTop(id) {
        if (id === IMAGE_TYPE.thumbnail) return '21.5%';

        return '';
    }

    getThumbnailBottomImage(id) {
        if (id === IMAGE_TYPE.thumbnail) return '17px';

        return '5px';
    }

    render() {
        const {
            fileName,
            image,
            isImageSaved,
            isOpenImageModal,
        } = this.state;

        const {
            classes,
            disabled,
            group,
            onChange,
            previewUrl,
            readOnly,
            width,
        } = this.props;

        const controlButtons = () => {
            if (readOnly) {
                return null;
            }

            return previewUrl !== '' ?
                (
                    <div>
                        <label
                            className={classes.uploadPhotoLink}
                            htmlFor={`${group.imageContainer.id}--file_upload`}
                        >
                            <input
                                accept={inputAllowedTypes}
                                className={classes.inputTypeFile}
                                id={`${group.imageContainer.id}--file_upload`}
                                onChange={
                                    (e) => this.onHandleImageUploaderChange(e, true)
                                }
                                type="file"
                            />

                            <Icon
                                size="medium"
                                style={{ float: 'left' }}
                                type="upload-1"
                            />
                        </label>

                        <Icon
                            onClick={this.onRemoveImage}
                            size="medium"
                            style={{ float: 'left' }}
                            type="delete"
                        />
                    </div>
                ) : (
                    <label htmlFor={`${group.imageContainer.id}--file_upload`}>
                        <div
                            className={classes.uploadPhotoLink}
                            style={{ marginLeft: '4px' }}
                        >
                            <input
                                accept="image/png, image/jpeg"
                                className={classes.inputTypeFile}
                                disabled={disabled}
                                id={`${group.imageContainer.id}--file_upload`}
                                name={group.imageContainer.id}
                                onChange={
                                    (e) => this.onHandleImageUploaderChange(e, true)
                                }
                                type="file"
                                value=""
                            />
                            {i18n('Upload')}
                        </div>
                    </label>
                );
        };

        return (
            <Grid.Column
                spacing={2}
                style={{
                    width,
                    paddingRight: '30px',
                }}
            >
                <div className={classes.headerColumn}>
                    <Box
                        alignItems="center"
                        display="flex"
                    >
                        {group.titleIconType !== false ? (
                            <Icon
                                size="xxlarge"
                                type={group.titleIconType ?? 'template'}
                            />
                        ) : null}

                        <Typography>
                            {group.title}

                            {group.required && group.imageContainer.src === '' ? (
                                <Box
                                    color="error.main"
                                    component="span"
                                    pl={0.5}
                                >
                                    *
                                </Box>
                            ) : null}
                        </Typography>
                    </Box>
                </div>

                <div className={classes.divContainer}>
                    <div
                        className="_image_preview"
                        style={{ width: '100%',
                            lineHeight: '32px',
                            marginTop: this.getThumbnailTop(group.id) }}
                    >
                        {group.imageContainer.src === '' && previewUrl === '' && image === null ?
                            (
                                <div
                                    className={classes.emptyImage}
                                    style={{
                                        height: this.getContainerHeight(group.id),
                                        minHeight: this.getContainerMinHeight(group.id),
                                        width: this.getContainerWidth(group.id),
                                    }}
                                >
                                    <Icon
                                        size="xlarge"
                                        type="image"
                                    />
                                </div>
                            ) :
                            (
                                <Image
                                    as="img"
                                    border={isImageSaved && previewUrl ? 3 : undefined}
                                    className={classes.image}
                                    name={group.imageContainer.id}
                                    src={image === null ? previewUrl : image}
                                    style={{
                                        width: this.getContainerWidth(group.imageContainer.id),
                                        height: this.getContainerHeight(group.imageContainer.id),
                                        maxWidth: this.getContainerWidth(group.imageContainer.id),
                                    }}
                                    suppressBackgroundImage
                                />
                            )}
                        {controlButtons()}
                    </div>

                    <Modal
                        classes={{
                            root: ClassNames(
                                classes.scrollContainer,
                            ),
                            scrollContainer: ClassNames(
                                classes.contentScrollContainer,
                            ),
                        }}
                        isOpen={isOpenImageModal}
                        maxHeight="90%"
                        onClose={this.onCloseImageModal}
                    >
                        <Modal.Content
                            classes={{
                                root: classes.contentWrapper,
                            }}
                        >
                            <ImageUploaderManager
                                fileName={fileName}
                                group={group}
                                onChange={onChange}
                                onCloseImageModal={this.onCloseImageModal}
                                onHandleImageUploaderChange={this.onHandleImageUploaderChange}
                                onSave={this.onSetImage}
                                previewUrl={image === null ? previewUrl : image}
                                uploadAll={false}
                            />
                        </Modal.Content>

                    </Modal>
                </div>
            </Grid.Column>
        );
    }
}

ImageUploaderContent.propTypes = propTypes;
ImageUploaderContent.defaultProps = defaultProps;

export default withStyles(styles)(ImageUploaderContent);
