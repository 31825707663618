import _, {
    noop,
} from 'lodash';
import React, { createRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { setElements } from './global/personRecord.actions.js';
import { USER_PERMISSIONS } from '../../global/userPermissionConstants.js';
import FormEntriesCarousel from './formEntriesCarousel/formEntriesCarousel';
import RelationshipConnectionsCarousel from './relationshipConnectionsCarousel';
import TabsContentPersonalPersonInfo from './tabsContentPersonalPersonInfo.jsx';
import UserAccessStore from '../../global/userAccessStore.js';
import Relationship from './models/relationship.model';

type PropTypes = {
    blockSize: number;
    clientHeight: number;
    isDrawerChild?: boolean;
    onRelationshipCardClick: () => void;
    personId?: number;
    relationshipData?: Relationship[];
    userAccess: UserAccessStore;
};

const mapStateToProps = (state) => {
    const {
        bootstrap: {
            securityContext: {
                userAccess,
            },
        },
        breakpoint: {
            winWidth,
            clientHeight,
        },
        people: {
            record: {
                index: {
                    isNotesDrawerExpanded,
                    isNotesDrawerOpen,
                },
                relationships: {
                    data: relationshipData,
                },
            },
        },
    } = state;

    return {
        clientHeight,
        isNotesDrawerExpanded,
        isNotesDrawerOpen,
        relationshipData,
        userAccess,
        winWidth,
    };
};

const LG_DW_SHADOW_HEIGHT = 8;
const LG_RCC_BACKGROUND_HEIGHT = 180;
const LG_RCC_HEIGHT = 221;
const LG_RCC_Y = -74;

function TabsContentPersonal(props: PropTypes) {
    const spPersonalRef = createRef<HTMLDivElement>();
    const spPersonalInnerRef = createRef<HTMLDivElement>();
    const spPersonalRcRef = createRef<HTMLDivElement>();

    const {
        blockSize,
        clientHeight,
        isDrawerChild,
        onRelationshipCardClick = noop,
        personId,
        relationshipData,
        userAccess,
    } = props;

    const isBlockMinWidth768 = blockSize >= 768;

    const canReadConnectionCardEntries = userAccess.hasPermission(
        USER_PERMISSIONS.readConnectionFormEntries,
    );

    useEffect(() => {
        setElements({
            spPersonal: spPersonalRef.current,
            spPersonalInner: spPersonalInnerRef.current,
            spPersonalRc: spPersonalRcRef.current || null,
        });
    }, [spPersonalInnerRef, spPersonalRcRef, spPersonalRef]);

    const relationshipConnectionInfoHeight = LG_RCC_HEIGHT + LG_RCC_BACKGROUND_HEIGHT + LG_RCC_Y;
    const extendedPersonInfoMinHeight =
        clientHeight - relationshipConnectionInfoHeight + LG_DW_SHADOW_HEIGHT;

    return (
        <div
            className="person_record--tabs_content person_record--tabs_content-personal"
            ref={spPersonalRef}
        >
            {!_.isEmpty(relationshipData) && (
                <RelationshipConnectionsCarousel
                    blockSize={blockSize}
                    desktopBackgroundHeight={LG_RCC_BACKGROUND_HEIGHT}
                    desktopHeight={LG_RCC_HEIGHT}
                    isDrawerChild={isDrawerChild}
                    onCardClick={onRelationshipCardClick}
                    ref={spPersonalRcRef}
                    style={{
                        transform: isBlockMinWidth768 ? `translate3d(0, ${LG_RCC_Y}px, 0)` : null,
                    }}
                />
            )}

            <div
                className="person_record--extended_person_info"
                ref={spPersonalInnerRef}
                style={{
                    marginTop: isBlockMinWidth768 ? `${LG_RCC_Y - LG_DW_SHADOW_HEIGHT}px` : null,
                    minHeight: `${extendedPersonInfoMinHeight}px`,
                }}
            >
                <TabsContentPersonalPersonInfo />

                {canReadConnectionCardEntries && (
                    <FormEntriesCarousel
                        blockSize={blockSize}
                        isDrawerChild={isDrawerChild}
                        personId={personId}
                    />
                )}
            </div>
        </div>
    );
}

export default connect(mapStateToProps)(TabsContentPersonal);
