import { i18n } from '../../global/constants.js';

export const BEM_BLOCK_NAME = 'communications_template_list_page';
export const BEM_BLOCK_TEMPLATE_MODAL_NAME = 'communications_central_edit_template_modal';
export const BEM_BLOCK_ADD_DRAWER = 'communications_central_add_drawer';
export const BEM_BLOCK_TEMPLATE_FORM = 'communications_central_Template_form';

export const DROPDOWN_TABLE_ACTIONS = {
    editName: 'communications_dropdown_table_actions_edit_name',
    editTemplate: 'communications_dropdown_table_actions_edit_template',
    duplicate: 'communications_dropdown_table_actions_duplicate',
};

export const DEFAULT_FILTER_OPTIONS = {
    campus: null,
    sortBy: 'name,asc',
    statusActive: true,
    statusInactive: true,
};

export const MAX_FILE_SIZE = 26214400; // Current limitation for UI only (25Mb)

export const SORT_OPTIONS = [
    {
        label: i18n('Name (A > Z)'),
        value: 'name,asc',
    }, {
        label: i18n('Name (Z > A)'),
        value: 'name,desc',
    }, {
        label: i18n('Last Entry Date (Asc)'),
        value: 'lastentrydate,asc',
    }, {
        label: i18n('Last Entry Date (Desc)'),
        value: 'lastentrydate,desc',
    }, {
        label: i18n('Modify Date (Asc)'),
        value: 'updatedate,asc',
    }, {
        label: i18n('Modify Date (Desc)'),
        value: 'updatedate,desc',
    }, {
        label: i18n('Published (Asc)'),
        value: 'published,asc',
    }, {
        label: i18n('Published (Desc)'),
        value: 'published,desc',
    },
];

export const SAVE_TEMPLATE_SUCCESS_MESSAGE = 'Template Saved!';
export const SAVE_TEMPLATE_ERROR_MESSAGE = 'Could Not Save Template!';
/**
 * Navigation related constants
 */

export const COMMUNICATIONS_CENTRAL_PATHS = {
    emailTemplateBuilder: 'email-template-builder',
    emailTemplates: 'email-templates',
    root: 'communications-central',
    templateLibrary: 'template-library',
    segment: 'segment-search',
    pushNotification: 'push-notification',
    communication: 'communication',
};
