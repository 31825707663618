import { combineReducers } from 'redux';

import baseTemplates from './baseTemplates.reducer';
import communication from './communication/communication.reducer';
import communicationPreviewer from './communication/communicationPreviewer.reducer';
import communications from './communication/communications.reducer';
import communicationsFilters from './communication/communicationsFilters.reducer';
import communicationTemplate from './communication/communicationTemplate.reducer';
import communicationTemplates from './communication/communicationTemplates.reducer';
import areFiltersDirty from './dirtyFilters.reducer';
import isFiltering from './filtering.reducer';
import filterOptions from './filterOptions.reducer';
import formData from './formData.reducer';
import inlineEditor from './inlineEditor.reducer';
import pushNotifications from './pushNotification/pushNotifications.reducer';
import searchQuery from './searchQuery.reducer';
import searchResults from './searchResults.reducer';
import segment from './segmentSearch/segment.reducer';
import segmentCriteriaCount from './segmentSearch/segmentCriteriaCount.reducer';
import segmentFilters from './segmentSearch/segmentFilters.reducer';
import segments from './segmentSearch/segments.reducer';
import selectedBaseTemplate from './selectedBaseTemplate.reducer';
import isUploading from './uploading.reducer';

const reducers = combineReducers({
    areFiltersDirty,
    baseTemplates,
    communication,
    communicationPreviewer,
    communications,
    communicationsFilters,
    communicationTemplate,
    communicationTemplates,
    filterOptions,
    formData,
    inlineEditor,
    isFiltering,
    isUploading,
    pushNotifications,
    searchQuery,
    searchResults,
    segment,
    segmentCriteriaCount,
    segments,
    segmentFilters,
    selectedBaseTemplate,
});

export default reducers;
