import filterOptionsReducer from '../../../../global/filterOptionsReducer.js';

const DEFAULT_FILTER_OPTIONS = {
    birthday: false,
    genderFemale: true,
    genderMale: true,
    isFirstTime: false,
    isSecondTime: false,
    sortBy: 'lastname,asc',
    status: 'CheckedIn',
    subVenueIds: null,
};

export default function (state, action) {
    return filterOptionsReducer(
        state,
        action.params,
        action.query,
        action.filters,
        DEFAULT_FILTER_OPTIONS,
        action.type === 'EventAttendeeCheckInActions.ON_RESET_SEARCH',
        action.type === 'EventAttendeeCheckInActions.ON_SEARCH_QUERY',
        action.type === 'EventAttendeeCheckInActions.ON_FILTERS',
        action.type === 'EventAttendeeCheckInActions.ON_APPLY_FILTERS',
        action.type === 'EventAttendeeCheckInActions.ON_CLEAR_FILTERS',
    );
}
