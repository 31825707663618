import cloneDeep from 'lodash/cloneDeep';
import { EventFlatOccurrence } from '../../event/global/models';
import { CampusSelectOption } from '../../global/models';
import { EMPTY_BUTTON_ID } from './constants';
import { DEFAULT_STATE as DEFAULT_FORM_STATE } from './digitalProgramButtonForm.reducer';
import ActionType from './digitalProgramButtonForm.types';
import Button from './models/button.model';
import ButtonFormState from './models/buttonFormState.model';
import CampusService from './models/campusService.model';
import DigitalProgramButtonType from './models/digitalProgramButtonType.model';
import FieldValue from './models/digitalProgramInfoFieldValue.model';
import Step from './models/step.model';

type STATE = {
    buttons: Button[];
    campusOptions: CampusSelectOption[] | null;
    campusServices: CampusService[] | null;
    initialButtonFormState: ButtonFormState;
    selectedButtonId: number | null;
    selectedButtonType: DigitalProgramButtonType | null;
    selectedCampus: CampusSelectOption;
    selectedServices: EventFlatOccurrence[] | null;
    showUnsavedModal: boolean | null;
    step: Step;
};

export const DEFAULT_STATE: STATE = {
    buttons: [],
    campusOptions: [],
    campusServices: [],
    initialButtonFormState: DEFAULT_FORM_STATE,
    selectedButtonId: null,
    selectedButtonType: null,
    selectedCampus: null,
    selectedServices: [],
    showUnsavedModal: false,
    step: Step.ButtonTypeSelection,
};

export type Action = {
    button?: Button;
    buttonId?: number;
    buttonType?: DigitalProgramButtonType;
    campusOptions?: CampusSelectOption[];
    campusServices?: CampusService[];
    field?: string;
    initialState?: ButtonFormState;
    selectedCampus?: CampusSelectOption;
    selectedServices?: EventFlatOccurrence[];
    showUnsavedModal?: boolean;
    step?: Step;
    type: ActionType;
    value?: FieldValue;
};

export default (state = DEFAULT_STATE, action: Action) => {
    switch (action?.type) {
        case ActionType.AddButton:
            return {
                ...state,
                selectedButtonId: EMPTY_BUTTON_ID,
                selectedButtonType: action.buttonType,
                buttons: [
                    ...state.buttons,
                    {
                        ...action.button,
                        order: state.buttons.length,
                    },
                ],
            };

        case ActionType.CancelButtonOngoingChanges:
            return {
                ...state,
                selectedButtonId: null,
                selectedButtonType: null,
                buttons: state.buttons
                    .filter((button) => (button.id !== EMPTY_BUTTON_ID)) // removes a potential before creation button preview
                    .map((button, index) => ({ // let's adjust the order for the React Sortable interface
                        ...button,
                        order: index,
                    })),
            };

        case ActionType.UpdateButtonAfterUpsert:
            return {
                ...state,
                selectedButtonId: null,
                selectedButtonType: null,
                buttons: state.buttons.map(
                    (button) => (
                        button.id === action.buttonId ?
                            {
                                ...action.button,
                            } : // just upserted button
                            { ...button } // button with no changes
                    ),
                ),
            };

        case ActionType.SetCampuses:
            return {
                ...state,
                campusOptions: cloneDeep(action.campusOptions),
            };

        case ActionType.SetCampusServices:
            return {
                ...state,
                campusServices: cloneDeep(action.campusServices),
            };

        case ActionType.SetSelectedCampus:
            return {
                ...state,
                selectedCampus: action.selectedCampus,
            };

        case ActionType.SetStep:
            return {
                ...state,
                step: action.step,
            };

        case ActionType.SetSelectedServices:
            return {
                ...state,
                selectedServices: cloneDeep(action.selectedServices),
            };

        case ActionType.SetInfoField:
            return {
                ...state,
                [action.field]: action.value,
            };

        case ActionType.SetInitialState:
            return {
                ...state,
                initialButtonFormState: action.initialState,
            };

        default:
            return state;
    }
};
