import ActionType from '../communicationsCentral.actionTypes';
import { Action } from '../../global/models';
import { DEFAULT_FILTER_OPTIONS } from '../global/constants';

const INITIAL_STATE = DEFAULT_FILTER_OPTIONS;

export default (state = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case ActionType.SET_FILTER_OPTIONS:
            return action.payload;
        case ActionType.CLEAR_FILTER_OPTIONS:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
};
